import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import './CampaignItem.less';
import { ReactComponent as SuccessSvg } from '@assets/icons/success.svg';
import { ReactComponent as TimelineSvg } from '@assets/icons/timeline.svg';
import { ReactComponent as PencilSvg } from '@assets/icons/pencil-write.svg';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { ReactComponent as SortArrowSvg } from '@assets/icons/sort-arrow.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { ReactComponent as SearchSvg } from '@assets/icons/search.svg';
import { ReactComponent as SortListSvg } from '@assets/icons/sort-list.svg';
import { ReactComponent as SortTilesSvg } from '@assets/icons/sort-tiles.svg';
import { Input, Tabs, Dropdown, Menu, Button, message } from 'antd';
import CampaignTimeline from '@components/CampaignTimeline';
import {
  setCurrentCampaign,
  setFavoriteCampaignAction,
  updateCampaignAction,
  updateCampaignCounters
} from '@redux/actions/campaignAction';
import { getMembersList, getCampaign, getMembersCount } from '@api/Campaign';
import { useCurrentWorkspace } from '@hooks/workspace';
import { usePrevious, useTypedSelector } from '@hooks';
import { AssetForApprovalDto, PageDto } from '@api/Api';
import {
  toggleBillingModal,
  toggleCampaignInfoModal,
  toggleCampaignInvitationModal,
  toggleEntityDeletionModal,
  toggleRenameEntityModal
} from '@redux/actions/modalAction';
import {
  addAsset,
  addFolder,
  setAssetList,
  updateAsset,
  updateFolderList,
  updateFolders
} from '@redux/actions/assetsAction';
import { AssetsActionTypes } from '@redux/types/assetsType';
import {
  setWorkspaceCounters,
  updateAssetsWorkspaceCounters,
  updateCampaignsWorkspaceCounters
} from '@redux/actions/workspaceCountersAction';
import {
  useWebSocketDebouncedSubscription,
  useWebSocketSubscription
} from '@hooks/useWebSocketClient';
import { getAsset } from '@api/Asset';
import { useLayout } from '@layouts/PageLayout';
import { toast } from 'react-toastify';
import {
  ActionToast,
  EntityAccessHasBeenChanged,
  EntityHasBeenRemoved
} from '@components/Toasts';
import { useOrganization } from '@components/OrganizationBoundary';
import {
  getCountListAssetsWithApproval,
  getListAssetsWithApproval
} from '@api/Approval';
import AssetsTab from '@pages/Campaigns/CampaignItem/components/AssetsTab/AssetsTab';
import TasksTab from '@pages/Campaigns/CampaignItem/components/TasksTab/TasksTab';
import { getAssetItem } from '@redux/actions/mediaViewerAction';
import classNames from 'classnames';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import { nonNull } from '@helpers/non-null';
import { campaignStateSelector as tasksCampaignStateSelector } from '@redux/selectors/tasks';
import {
  fetchCampaignTaskBoards,
  fetchTaskCustomFields
} from '@redux/actions/tasks';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { useFetch } from '@hooks/useFetch';
import { debounce } from '@helpers/debounce';
import { TasksOrderBy } from '@redux/types/tasks';
import {
  setListParamsInCampaign,
  setListsNeedRefreshInCampaign,
  setScrollTopInCampaign,
  setTaskBoardOpenedInCampaign
} from '@redux/reducers/tasks';
import TasksListControls from '@pages/Campaigns/CampaignItem/components/TasksListControls/TasksListControls';
import AvatarsGroup from '@components/AvatarsGroup/AvatarsGroup';
import ApprovalsTab from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/ApprovalsTab';
import { useQuery } from '@tanstack/react-query';

const { TabPane } = Tabs;

type copyForSearchProps = {
  [id: string]: { copy: string; width: number };
};

function Operations({
  activeTabKey,
  onAssetSearch,
  onApprovalsSearch,
  isTileView,
  setIsTileView,
  initOrderBy,
  onOrderBy
}: {
  activeTabKey: string;
  onAssetSearch: (a: string | undefined) => Promise<void>;
  onApprovalsSearch: (a: string | undefined) => Promise<void>;
  isTileView: boolean;
  setIsTileView: (a: boolean) => void;
  initOrderBy?: string;
  onOrderBy: (a: any) => void;
}) {
  const [searchQuery, setSearchQuery] = useState<string | undefined>('');
  const [selectedSorter, setSelectedSorter] = useState<string | undefined>(
    initOrderBy
  );

  const copyForSearch: copyForSearchProps = {
    assets: { copy: 'asset or folder', width: 230 },
    approvals: { copy: 'approvals', width: 200 }
  };
  const showViewToggle = activeTabKey === 'assets';
  const showSortMenu = activeTabKey !== 'approvals';
  const { copy } = copyForSearch[activeTabKey];
  return (
    <div className="actions-container" id="operations-container">
      <Input
        style={{ width: '100%', marginRight: 16, padding: '8px 12px' }}
        placeholder={`Search ${copy}`}
        prefix={<SearchSvg />}
        size="large"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          if (activeTabKey === 'approvals') {
            onApprovalsSearch(e.target.value || undefined);
          }
          if (activeTabKey === 'assets') {
            onAssetSearch(e.target.value || undefined);
          }
        }}
      />
      {showSortMenu && (
        <Dropdown
          getPopupContainer={() =>
            document.querySelector('#operations-container') as HTMLElement
          }
          overlay={
            <Menu
              selectedKeys={[selectedSorter as string]}
              onClick={({ key }) => {
                setSelectedSorter(key);
                onOrderBy(key);
                onAssetSearch(searchQuery || undefined);
              }}
            >
              <span className="help_dropdown__title">Sort by</span>
              <Menu.Item key="order:DESC">
                <span className="help_dropdown__dot" /> Custom
              </Menu.Item>
              <Menu.Item key="createdAt:DESC">
                <span className="help_dropdown__dot" /> Date created
              </Menu.Item>
              {activeTabKey === 'assets' && (
                <Menu.Item key="modifiedAt:DESC">
                  <span className="help_dropdown__dot" /> Date modified
                </Menu.Item>
              )}
            </Menu>
          }
          placement="bottomLeft"
          overlayClassName="help_dropdown"
          trigger={['click']}
        >
          <Button
            style={{ marginRight: 20, height: 'auto' }}
            type="text"
            icon={<SortArrowSvg />}
          >
            Sort
          </Button>
        </Dropdown>
      )}

      {showViewToggle && (
        <>
          <Button
            type="text"
            style={{ padding: 0, height: 'auto' }}
            className="select-assets-view-btn"
            onClick={() => {
              setIsTileView(true);
              if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
              }
            }}
          >
            <SortTilesSvg
              style={{ marginRight: 0 }}
              className={isTileView ? 'select-assets-view-icon' : null}
            />
          </Button>
          <div className="line" />
          <Button
            type="text"
            className="select-assets-view-btn"
            style={{ padding: 0, height: 'auto' }}
            onClick={() => {
              setIsTileView(false);
              if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
              }
            }}
          >
            <SortListSvg
              style={{ marginRight: 0 }}
              className={!isTileView ? 'select-assets-view-icon' : null}
            />
          </Button>
        </>
      )}
    </div>
  );
}

const draggableAssetsOrder = 'order:DESC';
const defaultAssetsOrder = draggableAssetsOrder;
const assetsOrderByKey = 'assets-order-by';

function CampaignItemInWorkspace() {
  const { currentView, setCurrentView } = useLayout();
  const [workspace] = useCurrentWorkspace();
  const { currentOrganization } = useOrganization(false);
  const campaign = useTypedSelector(({ campaign }) =>
    nonNull(campaign.selectedCampaign)
  );
  const assetData = useTypedSelector(({ assets }) => assets.files);
  const campaignInfoModal = useTypedSelector(
    ({ modal }) => modal.campaignInfoModal
  );
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const tabKeyParam = searchParams.get('tab') ?? 'assets';
  const dispatch = useTypedDispatch();
  const [openTimeline, setOpenTimeline] = useState<boolean>(false);
  const [activeTabKey, _setActiveTabKey] = useState<string>(tabKeyParam);

  const paramsRef = useRef(params);
  paramsRef.current = params;
  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;
  const setActiveTabKey = useCallback(
    (key: string) => {
      if (searchParamsRef.current.get('tab') !== key) {
        searchParamsRef.current.set('tab', key);
        setSearchParams(searchParamsRef.current);
      }
      _setActiveTabKey(key);
    },
    [_setActiveTabKey, setSearchParams]
  );
  if (tabKeyParam !== activeTabKey) {
    _setActiveTabKey(tabKeyParam);
  }

  const [orderBy, setOrderBy] = useState<any>(() => {
    if (tabKeyParam === 'assets') {
      return localStorage.getItem(assetsOrderByKey) || defaultAssetsOrder;
    }
    return undefined;
  });
  const [searchQuery, setSearchQuery] = useState<string | undefined>('');
  const [approvals, setApprovals] = useState<
    PageDto & { edges: { node: AssetForApprovalDto; cursor: string }[] }
  >();
  const [approvalsCount, setApprovalsCount] = useState<number>(0);
  const [approvalsLoading, setApprovalsLoading] = useState(false);

  const getApprovalsData = useCallback(async () => {
    if (!campaign?.id) return;
    setApprovalsLoading(true);
    const { data } = await getListAssetsWithApproval({
      workspaceId: workspace.id,
      campaignId: campaign.id,
      searchQuery
    });
    const {
      data: { count }
    } = await getCountListAssetsWithApproval({
      workspaceId: workspace.id,
      campaignId: campaign.id,
      searchQuery
    });
    setApprovalsCount(count);
    setApprovals(data);
    setApprovalsLoading(false);
  }, [campaign.id, searchQuery, workspace.id]);

  useEffect(() => {
    getApprovalsData();
  }, [getApprovalsData, workspace.id, campaign?.id, searchQuery]);

  const membersInPrivateCampaignQuery = useQuery({
    queryKey: ['campaign', 'members', 'list', 'private', campaign?.id],
    keepPreviousData: true,
    enabled: !!campaign?.isPrivate,
    queryFn: async ({ signal }) => {
      const { data } = await getMembersList(
        {
          campaignId: nonNull(campaign?.id),
          limit: 3,
          onlyThatHaveAccessToPrivate: true
        },
        { signal }
      );
      return data;
    }
  });
  const membersInPrivateCampaignCountQuery = useQuery({
    queryKey: ['campaign', 'members', 'count', 'private', campaign?.id],
    keepPreviousData: true,
    enabled: !!campaign?.isPrivate,
    queryFn: async ({ signal }) => {
      const { data } = await getMembersCount(
        {
          campaignId: nonNull(campaign?.id),
          onlyThatHaveAccessToPrivate: true
        },
        { signal }
      );
      return data.count;
    }
  });
  const membersInPrivateCampaign = useMemo(
    () => membersInPrivateCampaignQuery.data?.edges.map((x) => x.node) ?? [],
    [membersInPrivateCampaignQuery.data]
  );
  const membersInPrivateCampaignCount =
    membersInPrivateCampaignCountQuery.data ?? 0;

  useWebSocketSubscription(async (msg) => {
    if (!campaign) return;

    if (msg.causedByMe && msg.payload.reason !== 'upload') return;
    const {
      data: { asset }
    } = await getAsset({ assetId: msg.payload.assetId });

    if (msg.payload.reason !== 'moved-to-folder') {
      const isRestoring = msg.payload.reason === 'restored-from-recycle-bin';
      dispatch(
        updateAssetsWorkspaceCounters(isRestoring ? 'restore' : 'create', asset)
      );
    }

    if (msg.payload.campaignId !== campaign.id) return;
    if (msg.payload.reason !== 'moved-to-folder')
      dispatch(updateCampaignCounters('addFile', msg.payload.folderId));
    if (msg.payload.folderId) {
      const {
        data: { asset }
      } = await getAsset({ assetId: msg.payload.folderId, isFolder: true });
      dispatch(updateFolderList(asset));
      return;
    }
    dispatch(addAsset(msg.payload.assetId));
  }, 'asset-created');

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.assetId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      const asset = await getAssetItem({
        assetId: lastMessage.payload.assetId
      })(dispatch);
      if (!approvals) return;
      const needToRemove = !asset.asset?.versions[0].approvalWorkflow;
      if (needToRemove) {
        const newApprovals = approvals.edges.filter(
          (el) => el.node.id !== asset.id
        );
        setApprovalsCount(approvalsCount - 1);
        setApprovals(newApprovals as any);
        return;
      }
      const isInList = approvals.edges.find((el) => el.node.id === asset.id);
      if (!isInList) {
        const newApprovals = {
          ...approvals,
          edges: [{ node: asset as any, cursor: '' }, ...approvals.edges]
        };
        setApprovalsCount(approvalsCount + 1);
        setApprovals(newApprovals);
        return;
      }
      const newApprovals = {
        ...approvals,
        edges: approvals.edges.map((el) => {
          if (el.node.id === asset.id) return { ...el, node: asset as any };
          return el;
        })
      };
      setApprovals(newApprovals);
    },
    1000,
    (msg) => {
      if (!campaign || msg.payload.campaignId !== campaign.id) return false;
      if (msg.causedByMe) return false;
      if (msg.payload.reason === 'approval-workflow-changed') return true;
      return false;
    },
    'asset-updated'
  );

  useWebSocketSubscription(async (msg) => {
    if (!campaign || msg.payload.campaignId !== campaign.id) return;
    if (msg.causedByMe) return;
    if (msg.payload.reason !== 'moved-to-folder')
      dispatch(updateCampaignCounters('deleteFile', msg.payload.folderId));

    const isAssetRemovedFromDBList =
      msg.payload.reason === 'deleted-permanently' ||
      msg.payload.reason === 'added-to-stack';
    if (isAssetRemovedFromDBList) {
      const isPermanent = msg.payload.reason === 'deleted-permanently';
      const asset = assetData.edges?.find(
        (el) => el.node.id === msg.payload.assetId
      )?.node;
      if (asset)
        dispatch(
          updateAssetsWorkspaceCounters(
            isPermanent ? 'deletePermanent' : 'addToStack',
            asset
          )
        );
      else dispatch(setWorkspaceCounters(msg.payload.workspaceId));

      dispatch({
        type: AssetsActionTypes.DELETE_ASSET,
        payload: {
          assetId: msg.payload.assetId,
          folderId: msg.payload.folderId
        }
      });
      return;
    }
    const {
      data: { asset }
    } = await getAsset({
      assetId: msg.payload.assetId,
      isDeleted: msg.payload.reason !== 'moved-to-folder'
    });
    dispatch(updateAssetsWorkspaceCounters('delete', asset));
    dispatch({
      type: AssetsActionTypes.DELETE_ASSET,
      payload: { assetId: msg.payload.assetId, folderId: msg.payload.folderId }
    });
  }, 'asset-removed');

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.assetId,
    (messages) => {
      const lastMessage = messages[messages.length - 1];
      dispatch(updateAsset(lastMessage.payload.assetId));
    },
    1000,
    (msg) => {
      if (!campaign || msg.payload.campaignId !== campaign.id) return false;
      if (msg.payload.folderId !== null) return false;
      if (msg.causedByMe && msg.payload.reason !== 'conversion-status-changed')
        return false;
      return true;
    },
    'asset-updated'
  );

  useWebSocketSubscription(async (msg) => {
    if (!campaign || msg.payload.campaignId !== campaign.id) return;
    if (msg.causedByMe) return;
    dispatch(updateCampaignsWorkspaceCounters('delete', campaign));
    toast(<EntityHasBeenRemoved entity="campaign" name={campaign?.name} />, {
      hideProgressBar: true,
      style: { height: 66, width: 420 }
    });
    navigate('../');
    dispatch(setCurrentCampaign(null));
  }, 'campaign-removed');

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.campaignId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      const {
        data: { campaign }
      } = await getCampaign({
        campaignId: lastMessage.payload.campaignId
      });

      dispatch(updateCampaignAction(campaign));
    },
    1000,
    (msg) => {
      if (!campaign || msg.payload.campaignId !== campaign.id) return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'campaign-updated'
  );

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.campaignId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      const { campaignId, reason } = lastMessage.payload;

      try {
        const {
          data: { campaign }
        } = await getCampaign({
          campaignId
        });
        if (reason === 'maybe-lost-access') {
          dispatch(updateCampaignAction(campaign));
        }
      } catch (e) {
        if (reason === 'maybe-lost-access') {
          toast(<EntityAccessHasBeenChanged entity="campaign" />, {
            hideProgressBar: true,
            style: { height: 66, width: 420 }
          });
          if (campaignInfoModal?.visible)
            dispatch(toggleCampaignInfoModal(null));
          dispatch(updateCampaignsWorkspaceCounters('lostAccess', campaign));
          navigate('../');
        }
      }
    },
    1000,
    (msg) => {
      if (!campaign || msg.payload.campaignId !== campaign.id) return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'campaign-access-changed'
  );

  useWebSocketSubscription(async (msg) => {
    if (!campaign || msg.payload.campaignId !== campaign.id) return;
    if (msg.causedByMe) return;
    dispatch(updateCampaignCounters('addFolder'));
    dispatch(addFolder(msg.payload.folderId));
  }, 'folder-created');

  useWebSocketSubscription(async (msg) => {
    if (!campaign || msg.payload.campaignId !== campaign.id) return;
    if (msg.causedByMe) return;
    dispatch(updateCampaignCounters('deleteFolder'));
    dispatch({
      type: AssetsActionTypes.DELETE_FOLDER,
      payload: { folderId: msg.payload.folderId }
    });
  }, 'folder-removed');

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.folderId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      const { folderId, newName } = lastMessage.payload;
      dispatch({
        type: AssetsActionTypes.RENAME_FOLDER,
        payload: { folderId, name: newName }
      });
    },
    1000,
    (msg) => {
      if (!campaign || msg.payload.campaignId !== campaign.id) return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'folder-updated'
  );

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.folderId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      const { reason } = lastMessage.payload;
      try {
        const {
          data: { asset }
        } = await getAsset({
          assetId: lastMessage.payload.folderId,
          isFolder: true
        });
        if (reason === 'maybe-lost-access') {
          dispatch(updateFolders(asset));
          return;
        }
        dispatch(updateCampaignCounters('addFolder'));
        dispatch({
          type: AssetsActionTypes.CREATE_FOLDER,
          payload: { folder: asset }
        });
      } catch (e) {
        if (reason === 'maybe-lost-access') {
          dispatch({
            type: AssetsActionTypes.DELETE_FOLDER,
            payload: { folderId: lastMessage.payload.folderId }
          });
          dispatch(updateCampaignCounters('deleteFolder'));
        }
      }
    },
    1000,
    (msg) => {
      if (!campaign || msg.payload.campaignId !== campaign.id) return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'folder-access-changed'
  );

  const loadMoreApprovals = async () => {
    if (!approvals) return;
    if (!campaign) return;
    const { data } = await getListAssetsWithApproval({
      workspaceId: workspace.id,
      campaignId: campaign.id,
      needMyDecision: false,
      searchQuery,
      after: approvals?.endCursor || ''
    });
    setApprovals((prev) => ({
      ...prev,
      ...data,
      edges: [...(prev?.edges || []), ...data.edges]
    }));
  };

  // TASKS
  const workspaceId = workspace.id;
  const campaignId = campaign.id;
  const campaignIdRef = useRef(campaignId);
  campaignIdRef.current = campaignId;
  const tasksCount = useTypedSelector(
    (state) => tasksCampaignStateSelector(state, { campaignId }).count
  );

  useEffect(() => {
    dispatch(setListsNeedRefreshInCampaign({ campaignId }));
  }, [campaignId, dispatch]);

  const prevActiveTabKey = useRef(activeTabKey);
  useEffect(() => {
    if (activeTabKey !== prevActiveTabKey.current) {
      prevActiveTabKey.current = activeTabKey;
      if (activeTabKey === 'tasks') {
        dispatch(
          setListsNeedRefreshInCampaign({ campaignId: campaignIdRef.current })
        );
      }
    }
  }, [activeTabKey, dispatch]);

  const canRestoreTasksScroll = useTypedSelector(
    (state) =>
      activeTabKey === 'tasks' &&
      tasksCampaignStateSelector(state, { campaignId }).boards.fetch
        .initiallyLoaded
  );
  const tasksScrollTop = useTypedSelector(
    (state) => tasksCampaignStateSelector(state, { campaignId }).scrollTop
  );
  const initiallyLoaded = useTypedSelector(
    (state) =>
      tasksCampaignStateSelector(state, { campaignId }).boards.fetch
        .initiallyLoaded
  );
  const tasksScrollTopRef = useRef(tasksScrollTop);
  tasksScrollTopRef.current = tasksScrollTop;
  const tasksScrollContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!canRestoreTasksScroll) return;
    const boardId = searchParamsRef.current.get('boardId');
    if (boardId) return;
    requestAnimationFrame(() => {
      if (!tasksScrollContainer.current) return;
      tasksScrollContainer.current.scrollTop = tasksScrollTopRef.current;
    });
  }, [workspaceId, campaignId, canRestoreTasksScroll, dispatch]);
  useEffect(() => {
    if (paramsRef.current.campaignId !== campaignId) return;
    if (!initiallyLoaded) return;
    const boardId = searchParamsRef.current.get('boardId');

    if (boardId) {
      dispatch(
        setTaskBoardOpenedInCampaign({
          value: true,
          campaignId,
          boardId
        })
      );
      setTimeout(() => {
        document
          .querySelector(`[data-board-key="${boardId}"]`)
          ?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
      }, 200);
    }

    searchParamsRef.current.delete('boardId');
    setSearchParams(searchParamsRef.current, { replace: true });
  }, [initiallyLoaded, campaignId, dispatch, setSearchParams]);

  useFetch({
    key: `campaign-custom-fields-${campaignId}`,
    selector: (state) =>
      tasksCampaignStateSelector(state, { campaignId }).customFields.fetch,
    fetch: (fetchType) => {
      return dispatch(fetchTaskCustomFields({ campaignId, fetchType }));
    }
  });
  const boardsLoading = useFetch({
    key: `campaign-task-board-${campaignId}`,
    selector: (state) =>
      tasksCampaignStateSelector(state, { campaignId }).boards.fetch,
    fetch: (fetchType) => {
      return dispatch(
        fetchCampaignTaskBoards({
          campaignId,
          fetchType
        })
      );
    }
  });
  const onTasksOrderByChange = useCallback(
    (value: TasksOrderBy) => {
      dispatch(
        setListParamsInCampaign({
          orderBy: value,
          campaignId: campaignIdRef.current
        })
      );
      if (tasksScrollContainer.current) {
        tasksScrollContainer.current.scrollTop = 0;
      }
    },
    [dispatch]
  );
  const onTasksSearchQueryChange = useCallback(
    (value: string) => {
      dispatch(
        setListParamsInCampaign({
          searchQuery: value,
          campaignId: campaignIdRef.current
        })
      );
      if (tasksScrollContainer.current) {
        tasksScrollContainer.current.scrollTop = 0;
      }
    },
    [dispatch]
  );
  const onScroll = useMemo(
    () =>
      debounce((e: React.SyntheticEvent<HTMLDivElement>) => {
        const { scrollTop } = e.target as HTMLDivElement;
        tasksScrollTopRef.current = scrollTop;
        dispatch(
          setScrollTopInCampaign({
            value: scrollTop,
            campaignId: campaignIdRef.current
          })
        );
      }, 500),
    [dispatch]
  );
  // TASKS

  return (
    <>
      <div>
        <div className="header-container">
          <div className="title-container" id="CAMPAIGN_NAME">
            <span>{campaign?.name}</span>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      dispatch(
                        toggleCampaignInfoModal({
                          activeTab: 'details',
                          campaign,
                          visible: true
                        })
                      );
                    }}
                  >
                    Campaign info
                  </Menu.Item>
                  {campaign?.permissions.changeDetails && (
                    <Menu.Item
                      key="Rename"
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        if (!campaign) return;
                        dispatch(
                          toggleRenameEntityModal({
                            entity: campaign,
                            page: 'campaign',
                            visible: true
                          })
                        );
                      }}
                    >
                      Rename
                    </Menu.Item>
                  )}

                  {campaign?.permissions?.inviteContributors && (
                    <UpgradePlanTooltip
                      text=" to a paid plan to add external contributors."
                      isCanVisible={
                        !currentOrganization?.entity?.features
                          .externalContributors
                      }
                      innerStyle={{
                        transform: 'translate(0, -15px)'
                      }}
                    >
                      <Menu.Item
                        onClick={({ domEvent }) => {
                          if (
                            !currentOrganization?.entity?.features
                              .externalContributors
                          ) {
                            if (
                              !currentOrganization?.entity?.permissions.billing
                            )
                              return;
                            dispatch(toggleBillingModal({ visible: true }));
                            return;
                          }

                          domEvent.stopPropagation();
                          dispatch(
                            toggleCampaignInvitationModal({
                              visible: true,
                              campaign
                            })
                          );
                        }}
                      >
                        <div
                          style={
                            !currentOrganization?.entity?.features
                              .externalContributors
                              ? {
                                  display: 'flex',
                                  alignItems: 'center'
                                }
                              : undefined
                          }
                        >
                          Add сontributor{' '}
                          {!currentOrganization?.entity?.features
                            .externalContributors && (
                            <UpgradePlanSvg style={{ marginLeft: 4 }} />
                          )}
                        </div>
                      </Menu.Item>
                    </UpgradePlanTooltip>
                  )}
                  {campaign?.permissions?.changeAdditionalPermissions && (
                    <Menu.Item
                      onClick={() => {
                        if (
                          !currentOrganization?.entity?.features
                            .externalContributors
                        ) {
                          if (!currentOrganization?.entity?.permissions.billing)
                            return;
                          dispatch(toggleBillingModal({ visible: true }));
                          return;
                        }
                        dispatch(
                          toggleCampaignInfoModal({
                            activeTab: 'settings',
                            campaign,
                            visible: true
                          })
                        );
                      }}
                    >
                      <UpgradePlanTooltip
                        text=" to a paid plan to manage contributor permissions."
                        isCanVisible={
                          !currentOrganization?.entity?.features
                            .externalContributors
                        }
                        placement="right"
                        innerStyle={{
                          transform: 'translate(-30px, 0)'
                        }}
                      >
                        <div
                          style={
                            !currentOrganization?.entity?.features
                              .externalContributors
                              ? {
                                  display: 'flex',
                                  alignItems: 'center'
                                }
                              : undefined
                          }
                        >
                          Сontributor permissions{' '}
                          {!currentOrganization?.entity?.features
                            .externalContributors && (
                            <UpgradePlanSvg style={{ marginLeft: 4 }} />
                          )}
                        </div>
                      </UpgradePlanTooltip>
                    </Menu.Item>
                  )}
                  <Menu.Item
                    onClick={() => {
                      if (!campaign) return;
                      if (!campaign?.isFavorite) {
                        dispatch(
                          setFavoriteCampaignAction(
                            {
                              campaignId: campaign.id || '',
                              isFavorite: !campaign.isFavorite
                            },
                            false
                          )
                        );

                        dispatch(
                          updateCampaignsWorkspaceCounters(
                            campaign?.isFavorite
                              ? 'unsetFavorite'
                              : 'setFavorite',
                            campaign
                          )
                        );

                        return;
                      }
                      dispatch(
                        setFavoriteCampaignAction(
                          {
                            campaignId: campaign.id || '',
                            isFavorite: !campaign.isFavorite
                          },
                          false
                        )
                      );

                      dispatch(
                        updateCampaignsWorkspaceCounters(
                          campaign?.isFavorite
                            ? 'unsetFavorite'
                            : 'setFavorite',
                          campaign
                        )
                      );
                      toast(
                        <ActionToast
                          title="Campaign has been removed from favorites"
                          onUndo={undefined}
                          description={undefined}
                          closeToast={undefined}
                        />,
                        {
                          hideProgressBar: true,
                          bodyClassName: 'toast_container--invitation'
                        }
                      );
                    }}
                  >
                    {!campaign?.isFavorite
                      ? 'Add to favorite'
                      : 'Remove from favorite'}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      navigator.clipboard.writeText(window.location.href);
                      message.success({
                        content: 'Copied link to clipboard',
                        className: 'message-dark-modal',
                        icon: <SuccessSvg className="anticon" />
                      });
                    }}
                  >
                    Share campaign link
                  </Menu.Item>
                  {campaign?.permissions?.delete && (
                    <Menu.Item
                      onClick={async () => {
                        dispatch(
                          toggleEntityDeletionModal({
                            entity: campaign,
                            page: 'campaign',
                            permanent: false,
                            visible: true,
                            func: () => {
                              dispatch(setCurrentCampaign(null));
                              navigate('../');
                            }
                          })
                        );
                      }}
                    >
                      Delete
                    </Menu.Item>
                  )}
                </Menu>
              }
              overlayClassName="campaign_menu_overlay"
              placement="bottomLeft"
              trigger={['click']}
            >
              <Button
                className="header-container__dropdown-trigger"
                type="text"
                size="small"
              >
                <ActionSvg style={{ marginRight: 0 }} />
              </Button>
            </Dropdown>
          </div>
          <div>
            {campaign?.isPrivate && (
              <Button
                type="text"
                className="campaign-users"
                onClick={() =>
                  dispatch(
                    toggleCampaignInfoModal({
                      campaign,
                      visible: true,
                      activeTab: 'members'
                    })
                  )
                }
              >
                <AvatarsGroup
                  membersType="campaign_members"
                  maxCount={3}
                  users={membersInPrivateCampaign}
                  totalCount={membersInPrivateCampaignCount}
                  avatarSize={32}
                />
              </Button>
            )}

            <Button
              className="timeline-button"
              type="default"
              size="small"
              onClick={() => setOpenTimeline(true)}
            >
              <TimelineSvg />
              <span>View timeline</span>
            </Button>
            <Button
              type="default"
              size="small"
              id="CAMPAIGN_INFO_BUTTON"
              className="info-button"
              onClick={() =>
                dispatch(
                  toggleCampaignInfoModal({
                    activeTab: 'details',
                    campaign,
                    visible: true
                  })
                )
              }
            >
              <PencilSvg />
              <span>Campaign info</span>
            </Button>
          </div>
        </div>
        <div className="menu-panel-container">
          <Tabs
            activeKey={activeTabKey}
            onChange={(key) => {
              setActiveTabKey(key);
            }}
            tabBarExtraContent={
              <>
                {activeTabKey !== 'tasks' && (
                  <Operations
                    activeTabKey={activeTabKey}
                    onApprovalsSearch={async (v) => {
                      setSearchQuery(v);
                    }}
                    onAssetSearch={(v): any => {
                      if (!campaign) return;
                      const searchQuery = v || undefined;
                      setSearchQuery(v);
                      dispatch(
                        setAssetList({
                          workspaceId: campaign.workspaceId,
                          campaignId: campaign.id,
                          isFolder: true,
                          searchQuery
                        })
                      );
                      dispatch(
                        setAssetList({
                          workspaceId: campaign.workspaceId,
                          campaignId: campaign.id,
                          isFolder: false,
                          searchQuery
                        })
                      );
                    }}
                    isTileView={currentView === 'tile'}
                    setIsTileView={(v) => setCurrentView(v ? 'tile' : 'list')}
                    initOrderBy={orderBy}
                    onOrderBy={(orderBy) => {
                      setOrderBy(orderBy);
                      if (activeTabKey === 'assets') {
                        localStorage.setItem(assetsOrderByKey, orderBy);
                      }
                    }}
                  />
                )}
                {activeTabKey === 'tasks' && (
                  <TasksListControls
                    campaignId={campaign.id}
                    onSearchQueryChange={onTasksSearchQueryChange}
                    onOrderByChange={onTasksOrderByChange}
                  />
                )}
              </>
            }
          >
            <TabPane
              tab={
                <>
                  Media{' '}
                  <span
                    className={classNames(
                      'count',
                      (campaign?.totalAssetsCount || 0) > 9 && 'count_big'
                    )}
                  >
                    {campaign?.totalAssetsCount}
                  </span>{' '}
                </>
              }
              key="assets"
            >
              <AssetsTab
                orderBy={orderBy}
                searchQuery={searchQuery}
                activeTabKey={activeTabKey}
              />
            </TabPane>
            <TabPane
              tab={
                <>
                  Approvals{' '}
                  <span
                    className={classNames(
                      'count',
                      (approvalsCount || 0) > 9 && 'count_big'
                    )}
                  >
                    {approvalsCount}
                  </span>{' '}
                </>
              }
              key="approvals"
            >
              {approvals && (
                <ApprovalsTab
                  loading={approvalsLoading}
                  searchQuery={searchQuery}
                  activeTabKey={activeTabKey}
                  approvals={approvals}
                  campaignId={campaign.id}
                  setApprovals={setApprovals}
                  setApprovalsCount={setApprovalsCount}
                  loadMoreApprovals={loadMoreApprovals}
                  getApprovalsData={getApprovalsData}
                />
              )}
            </TabPane>
            <TabPane
              tab={
                <div className="tasks-tab-pane">
                  Tasks{' '}
                  <span
                    className={classNames(
                      'count',
                      tasksCount > 9 && 'count_big'
                    )}
                  >
                    {tasksCount}
                  </span>{' '}
                </div>
              }
              key="tasks"
            >
              <div
                ref={tasksScrollContainer}
                className="collapse-container"
                onScroll={onScroll}
              >
                <TasksTab campaign={campaign} boardsLoading={boardsLoading} />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
      <CampaignTimeline
        campaignId={campaign.id || ''}
        visible={openTimeline}
        setVisible={setOpenTimeline}
      />
    </>
  );
}

function CampaignItem(props: any) {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const [currentWorkspace, setCurrentWorkspace] = useCurrentWorkspace(false);
  const { currentOrganization } = useOrganization();

  const prevWsPermissions = usePrevious({
    permissions: currentWorkspace?.permissions
  });
  const prevOrgPermissions = usePrevious({
    permissions: currentOrganization?.entity?.permissions
  });
  const campaign = useTypedSelector(
    ({ campaign }) => campaign.selectedCampaign
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentOrganization.entity?.permissions || !prevOrgPermissions) return;
    const newOrgPermission = JSON.stringify(
      currentOrganization.entity?.permissions
    );
    const oldOrgPermission = JSON.stringify(prevOrgPermissions);
    if (newOrgPermission === '{}' || oldOrgPermission === '{}') return;
    if (newOrgPermission === oldOrgPermission) return;
    (async () => {
      try {
        const result = await getCampaign({ campaignId: campaignId || '' });
        const { campaign } = result.data;
        dispatch(setCurrentCampaign(campaign));
      } catch (e) {
        message.error('This campaign is no longer available.');
        navigate('../');
      }
    })();
  }, [currentOrganization.entity?.permissions]);

  useEffect(() => {
    if (!currentWorkspace?.permissions || !prevWsPermissions) return;
    const newWsPermission = JSON.stringify(currentWorkspace.permissions);
    const oldWsPermission = JSON.stringify(prevWsPermissions);
    if (newWsPermission === '{}' || oldWsPermission === '{}') return;
    if (newWsPermission === oldWsPermission) return;
    (async () => {
      try {
        const result = await getCampaign({ campaignId: campaignId || '' });
        const { campaign } = result.data;
        dispatch(setCurrentCampaign(campaign));
      } catch (e) {
        navigate('../');
      }
    })();
  }, [currentWorkspace?.permissions]);

  useEffect(() => {
    if (!campaignId || campaignId === campaign?.id) return;
    (async () => {
      try {
        const result = await getCampaign({ campaignId: campaignId || '' });
        const { campaign } = result.data;
        dispatch(setCurrentCampaign(campaign));
      } catch (e) {
        navigate('../');
      }
    })();
  }, [campaignId, campaign, dispatch, navigate]);

  useEffect(() => {
    if (campaign && campaign.workspaceId !== currentWorkspace?.id) {
      setCurrentWorkspace(campaign.workspaceId);
    }
  }, [campaign, currentWorkspace, setCurrentWorkspace]);
  if (!campaign || !currentWorkspace) return null;
  if (campaign.workspaceId !== currentWorkspace.id) return null;
  return <CampaignItemInWorkspace {...props} />;
}

export default CampaignItem;
